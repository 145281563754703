<template>
  <div class="row logo-waybill-master">
  <div class="col-12">
      <p class="flash" style="font-size:15px" v-if="lockLogoSync">İrsaliyeler logo ile güncelleniyor, bu sayfayı kapatmayın, yeni bir pencere açarak tekrar istek göndermeyin. Lütfen bekleyin işleminiz yapılıyor.</p>
    </div>

    <div class="col-md-12">
      <form @submit.prevent="filterResult()">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0 logo-waybill-index-search-area">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-7 col-lg-5 col-md-5 col-sm-12 my-2 row px-0 mx-0">
                <input-filter :helper-text="$t('general.search')" :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')" title="general.search"></input-filter>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-2">
                <div class="col-6 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" :model.sync="filters.start_date"
                    name="start_time" title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-6 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" :model.sync="filters.end_date" name="end_date"
                    title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-4 px-1 mr-4 m-1 d-flex align-items-center justify-content-end row">
                <div class="col-4 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click.prevent="filterResult(null)">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-4 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>

                <div class="col-4 pr-0">
                  <button type="button"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    :disabled="lockLogoSync"
                    :class="{'disabled':lockLogoSync}"
                    @click="logoSync">
                    {{ $t('general.logo_sync') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12 mt-2">
      <dashboard-box :title="$t('waybill.waybill_list')">
        <template v-slot:preview>
          <div class="my-waybill-logo-index-data-table"> 
            <data-table :bordered="false" :borderless="true" :fields.sync="tableFields" :hover="false"
              :items="formattedWaybill" :no-border-collapse="true" :outlined="false" :infiniteId="infiniteId"
              :striped="false" tableVariant="''" @hitBottom="onHitBottom" @rowClicked="onRowClicked"></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";

import { GET_ITEMS, HANDLE_INFINITE_SCROLL, LOADING, UPDATE_ITEM_BY_ID } from "@/core/services/store/REST.module";

import { FILTER_LOGO, SET_FILTER_LOGO } from "@/core/services/store/waybill/waybill.module";

import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT, MOMENT_SYSTEM_TIME_FORMAT
} from "@/core/config/constant";
import draggable from 'vuedraggable'
import * as _ from "lodash";

export default {
  name: "WaybillIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    draggable
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_LOGO
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    formattedWaybill() {
      if (!this.waybills || this.waybills.data === null || this.waybills.data === undefined) return [];
      let temp = [];
      this.waybills.data.forEach(tempCurrent => {
        temp.push({
          'id': tempCurrent.id,
          'sendertitle': tempCurrent.sendertitle,
          'date': moment(tempCurrent.date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) + " " + moment(tempCurrent.time, LARAVEL_TIME_FORMAT).format(MOMENT_SYSTEM_TIME_FORMAT),
          'docdate': moment(tempCurrent.docdate, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'docnr': tempCurrent.docnr,
          'trcodename': tempCurrent.trcodename,
          'docexplain': tempCurrent.docexplain
        })
      })
      return temp;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      return [
        {
          'key': 'id',
          'label': "ID",
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'sendertitle',
          'label': this.$t('waybill.supplier_company'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'docnr',
          'label': this.$t('waybill.document_no'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'docdate',
          'label': this.$t('waybill.waybill_date'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'date',
          'label': this.$t('waybill.action_date'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'trcodename',
          'label': this.$t('waybill.waybill_type'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'docexplain',
          'label': this.$t('waybill.description'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        }
      ];
    }
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      lockLogoSync:false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      search: null,
      paginate: [],
      page: 1,
      perPage: 25,
      startDate: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
      endDate: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      waybills: { data: [] },
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      logoSyncUrl: 'api/waybills/logo/sync',
      waybillListUrl: 'api/waybill/logo-records'
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER_LOGO,
    }),
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onHitBottom($state = null) {
      let self = this;
      if (self.isUserGranted('Waybill', ['viewAny'])) {
        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));

        self.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.waybillListUrl,
          filters: filters,
          '$state': $state,
        }).then(result => {
          if (result.status) {
            self.waybills = result.data;
            if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        })
      }
    },
    filterResult() {
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.lockLogoSync=false;
          self.state.reset();
        }, 100);
      }
    },

    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        search: null,
      })
    },
    logoSync() {
      let self = this;
      self.lockLogoSync=true; 
      self.sweetAlertClose();
      self.sweetAlertLoading();
      if (this.isUserGranted('Waybill', ['create', 'update'])) {
        this.$store.dispatch(GET_ITEMS, {
          url: this.logoSyncUrl,
          filters: {}
        }).then((result) => {
          self.sweetAlertClose();
          if (result.status) {
            setTimeout(() => {
              self.lockLogoSync=false;
              self.sweetAlertSuccess(result.data.message).then(_ => { })
            }, 500);
          } else {
            setTimeout(() => {
              self.lockLogoSync=false;
              self.sweetAlertError(result.data);
            }, 500);
          }
        });
      }
    },
    onRowClicked(item, index, event) {
      if (this.isUserGranted('Waybill', ['create', 'update'])) {
        this.$router.push({ name: 'waybill.logo.entry', params: { id: item.id } });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("waybill.waybills") }
    ]);

    if (this.isUserGranted('Waybill', ['viewAny'])) {

      if (!this.filter) {
        this.resetFilters();
      }
      else {
        this.currentDepoType = this.filter.current_depo_type;
      }
      setTimeout(this.onHitBottom, 1000);
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/waybill/waybillLogoIndex.scss";
/*Mobile End*/
</style>
